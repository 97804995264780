import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css';
import Logo from './images/logolight6.png';

const Navigation = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const navRef = useRef(); // Ref for the nav



  const handleNavItemClick = () => {
    setIsNavExpanded(false);
  };

  /*const handleLogout = () => {
    localStorage.removeItem('session_id'); // Clear the session_id from local storage
    setIsLoggedIn(false); // Update login status
    navigate('/'); // Redirect to the homepage or another public page
  }; */

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsNavExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar" ref={navRef}>
      <div className="nav-brand">
        <Link to="/" onClick={handleNavItemClick}>
          <img src={Logo} alt="AI Assistant" className="nav-image" />
        </Link>
      </div>
      <button
        className="hamburger"
        onClick={() => setIsNavExpanded(!isNavExpanded)}
      >
        ≡
      </button>
      <ul className={`nav-items ${isNavExpanded ? 'expanded' : ''}`}>
        {/* Conditionally render the login or logout link */}
        <li>
          <Link to="/pricing" onClick={handleNavItemClick}>
            Pricing
          </Link>
        </li>
        <li>
          <Link to="/demo" onClick={handleNavItemClick}>
            Demo
          </Link>
        </li>
        <li>
          <Link to="/contactus" onClick={handleNavItemClick}>
            Request a Demo
          </Link>
        </li>
        <li>
          <Link to="https://app.aissie.com.au" onClick={handleNavItemClick}>
            Login / Signup
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
