import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './card.css'; // Ensure you have this CSS file for styles

const ExpandableCard = ({ image, title, summary, details }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    const detailsLines = details.split('\\n').map(line => line.trim());


    return (
        <div className={`expandable-card ${isExpanded ? 'expanded' : ''}`} onClick={toggleExpand}>
            <div className="card-content">
                {/*<div className='card-title'>
                    <h4>{title}</h4>
    </div>*/}
                <div className="card-image">
                    <img src={image} alt={title} />
                </div>
                <div className="card-details">
                    <p> {isExpanded ? (
                        <ul>
                            {detailsLines.map((line, index) => (
                                <li key={index}>{line.replace('-', '')}</li>
                            ))}
                        </ul>
                    ) : (
                        <p>{summary}</p>
                    )}</p>
                </div>
            </div>
        </div>
    );
};

ExpandableCard.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
};

export default ExpandableCard;
