import React from 'react';
import './PricingPage.css';
import { FaCheck } from 'react-icons/fa';
import ComparisonTable from './ComparisonTable'; // Import the new ComparisonTable component
import InfoIcon from '@mui/icons-material/HelpOutline';
import { IconButton, Tooltip } from '@mui/material';


const featureTooltips = {
    '15 chatbot responses per month': 'Receive 15 responses from your chatbot each month on the free plan.',
    '1 chatbot': 'You can create and manage 1 chatbot with this plan.',
    '400,000 characters/chatbot': 'The maximum character limit that can be processed per chatbot.',
    '1 team member': 'Invite 1 team member to collaborate on your chatbot.',
    'Unlimited links to train on': 'You can use as many links as you want to train your chatbot.',
    'Embed on unlimited websites': 'Embed your chatbot on any number of websites.',
    'View chat history': 'Access past interactions your chatbot has had with users.',
    'GPT-4o-mini model': 'The chatbot uses the GPT-4o-mini model for generating responses.',
    'Chatbots get deleted after 14 days of inactivity on the free plan.': 'Inactive chatbots on the free plan are deleted after 14 days of no activity.',
    '500 chatbot responses per month': 'Receive 500 responses from your chatbot each month.',
    '5,000,000 characters/chatbot': 'The maximum character limit that can be processed per chatbot.',
    '2 chatbots': 'You can create and manage up to 2 chatbots with this plan.',
    'Facebook and Instagram Messenger integration': 'Integrate your chatbot with Facebook and Instagram Messenger.',
    '10,000 chatbot responses/month': 'Receive 10,000 responses from your chatbot each month.',
    '10 chatbots': 'You can create and manage up to 10 chatbots with this plan.',
    '11,000,000 characters/chatbot': 'The maximum character limit that can be processed per chatbot.',
    'GPT-4o-mini and premium GPT-4o models': 'Access both GPT-4o-mini and premium GPT-4o models.',
    'Eveything in Business + Advanced CRM & API Integrations': 'All Business plan features plus advanced CRM and API integrations.',
    'Custom chatbot responses/month': 'The number of responses can be customised based on your needs.',
    'Prioritised Support': 'Get prioritised customer support for faster resolutions.',
    'Remove "Powered by AIssie"': 'Remove the "Powered by AIssie" branding from your chatbot.'
};
const pricingPlans = [
    {
        type: 'Trial',
        price: '$0',
        duration: 'Forever',
        features: [
            '15 chatbot responses per month',
            '1 chatbot',
            '400,000 characters/chatbot',
            '1 team member',
            'Unlimited links to train on',
            'Embed on unlimited websites',
            'View chat history',
            'GPT-4o-mini model',
            'Chatbots get deleted after 14 days of inactivity on the free plan.'
        ]
    },
    {
        type: 'Starter',
        price: '$49',
        duration: 'Per Month',
        features: [
            '500 chatbot responses per month',
            '2 chatbots',
            '5,000,000 characters/chatbot',
            '1 team member',
            'Unlimited links to train on',
            'Embed on unlimited websites',
            'View chat history',
            'GPT-4o-mini model',
            'Facebook and Instagram Messenger integration'
        ]
    },
    {
        type: 'Business',
        price: '$199',
        duration: 'Per Month',
        features: [
            '10,000 chatbot responses/month',
            '10 chatbots',
            '11,000,000 characters/chatbot',
            '10 team members',
            'Unlimited links to train on',
            'Embed on unlimited websites',
            'View chat history',
            'GPT-4o-mini and premium GPT-4o models',
            'Facebook and Instagram Messenger integration'
        ]
    },
    {
        type: 'Enterprise',
        price: 'Custom Pricing',
        duration: 'Per Month',
        features: [
            'Eveything in Business + Advanced CRM & API Integrations',
            'Custom chatbot responses/month',
            'Prioritised Support',
            'Remove "Powered by AIssie"',
        ]
    }
];

const PricingPage = () => {
    return (
        <div className="pricing-container">
            {pricingPlans.map(plan => (
                <div className="pricingCard" key={plan.type}>
                    <h2 className="pricingCard-title">{plan.type}</h2>
                    <p className="pricingCard-price">{plan.price}</p>
                    <p className="pricingCard-duration">{plan.duration}</p>
                    <ul className="pricingCard-features">
                        {plan.features.map((feature, index) => (

                            <li key={index} className="pricingCard-feature">
                                <Tooltip title={featureTooltips[feature] || ''} placement="top">
                                    <div className="feature-content">
                                        <FaCheck className="check-icon" /> {feature}
                                        <IconButton size="small">
                                            <InfoIcon style={{ color: 'rgba(0, 0, 0, 0.88)' }} />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </li>
                        ))}
                    </ul>
                    {plan.type === 'Enterprise' ? (
                        <a href="/contactus" className="subscribe-button">Contact Us</a>
                    ) :
                        <a href={`https://app.aissie.com.au/signup?subscription=${plan.type.toLowerCase()}`} className="subscribe-button">Subscribe</a>
                    }
                </div>
            ))}

            <ComparisonTable /> {/* Include the new ComparisonTable component here */}
        </div>
    );
}

export default PricingPage;