import React from 'react';
import './ComparisonTable.css';

const pricingPlans = [
    {
        type: 'Trial',
        price: '$0 Forever',
        features: {
            responses: '15 per month',
            chatbots: '1',
            characters: '400,000',
            teamMembers: '1',
            links: 'Unlimited',
            embed: true,
            history: true,
            model: 'GPT-4o-mini'
        }
    },
    {
        type: 'Starter',
        price: '$49 Per Month',
        features: {
            responses: '500 per month',
            chatbots: '2',
            characters: '5,000,000',
            teamMembers: '1',
            links: 'Unlimited',
            embed: true,
            history: true,
            model: 'GPT-4o-mini',
            integrations: 'Facebook/Instagram Messenger'
        }
    },
    {
        type: 'Business',
        price: '$199 Per Month',
        features: {
            responses: '5,000 per month',
            chatbots: 'Up to 10',
            characters: '11,000,000',
            teamMembers: 'Up to 10',
            links: 'Unlimited',
            embed: true,
            history: true,
            model: 'GPT-4o-mini and/or Premium GPT-4o',
            integrations: 'Facebook/Instagram Messenger'
        }
    },
    {
        type: 'Enterprise',
        price: 'Custom Pricing',
        features: {
            responses: 'Custom',
            chatbots: 'Custom',
            characters: 'Custom',
            teamMembers: 'Custom',
            links: 'Unlimited',
            embed: true,
            history: true,
            model: 'GPT-4o-mini and/or Premium GPT-4o',
            integrations: 'Facebook/Instagram Messenger + Custom Integrations',
            removeBranding: true
        }
    }
];


const featuresList = [
    { key: 'responses', label: 'Chatbot responses per month' },
    { key: 'chatbots', label: 'Number of chatbots' },
    { key: 'characters', label: 'Character Count' },
    { key: 'teamMembers', label: 'Team members' },
    { key: 'links', label: 'Links to train on' },
    { key: 'embed', label: 'Embed on unlimited websites' },
    { key: 'history', label: 'View chat history' },
    { key: 'model', label: 'Model type' },
    { key: 'integrations', label: 'Integrations' },
    { key: 'removeBranding', label: 'Remove "Powered by AIssie"' }
];

const ComparisonTable = () => {
    return (
        <div >
            <div className="compare-header">
            <h2>Compare the Plans</h2></div>
            <div className="comparison-table-container">
                <table className="comparison-table">
                    <thead>
                        <tr>
                            <th className="first-column">Features</th>
                            {pricingPlans.map(plan => (
                                <th key={plan.type}>
                                    <div className="plan-header">
                                        <h4>{plan.type}</h4>
                                        <div className="plan-price">{plan.price}</div>
                                        {plan.type === 'Enterprise' ? (
                        <a href="/contactus" className="subscribe-button">Contact Us</a>
                    ) : (
                        <a href={`https://app.aissie.com.au/signup?subscription=${plan.type.toLowerCase()}`} className="subscribe-button">Subscribe</a>
                    )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {featuresList.map(feature => (
                            <tr key={feature.key}>
                                <td className="first-column">{feature.label}</td>
                                {pricingPlans.map(plan => (
                                    <td key={plan.type}>
                                        {plan.features[feature.key] === true ? (
                                            <span className="checkmark">✔</span>
                                        ) : plan.features[feature.key] ? (
                                            plan.features[feature.key]
                                        ) : (
                                            <span className="crossmark">✘</span>
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};


export default ComparisonTable;