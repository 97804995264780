import React, { useState } from 'react';
import axios from 'axios';
import './ContactUs.css';

const ContactForm = ({ handleChange, handleSubmit, formData }) => (
  <form onSubmit={handleSubmit} className="contact-form">
    <div className="form-input-group">
      <label htmlFor="name">Name <span className="required-marker">*</span></label>
      <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="form-input" required />
    </div>
    <div className="form-input-group">
      <label htmlFor="email">Work Email <span className="required-marker">*</span></label>
      <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="form-input" required />
    </div>
    <div className="form-input-group">
      <label htmlFor="company">Company Name <span className="required-marker">*</span></label>
      <input type="text" id="company" name="company" value={formData.company} onChange={handleChange} className="form-input" required />
    </div>
    <div className="form-input-group">
      <label htmlFor="jobTitle">Job Title <span className="required-marker">*</span></label>
      <input type="text" id="jobTitle" name="jobTitle" value={formData.jobTitle} onChange={handleChange} className="form-input" required />
    </div>
    <div className="form-input-group">
      <label htmlFor="phone">Phone Number <span className="required-marker">*</span></label>
      <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} className="form-input" required />
    </div>
    <div className="form-input-group">
      <label htmlFor="message">Message <span className="required-marker">*</span></label>
      <textarea id="message" name="message" value={formData.message} onChange={handleChange} className="form-input" rows="5" required></textarea>
    </div>
    <button type="submit" className="submit-button">Request a Demo</button>
  </form>
);

const ContactUs = () => {
  const initialFormData = { name: '', email: '', company: '', jobTitle: '', phone: '', message: '' };
  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/send_email`, formData);
      setSuccessMessage(response.data.message);
      setFormData(initialFormData);
    } catch (error) {
      setErrorMessage('Error sending email. Please try again later.');
      console.error('Error sending email:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="contact-container">
      <h2 className="heading">Request a Demo</h2>
      <p className="intro-text">Would you like to see how our AIssie bot performs with your own knowledge base? Please reach out to us and we'll contact you as soon as we can!</p>
      {isLoading && <div className="loading-spinner"></div>}
      {successMessage && <div className="alert-message success-message">{successMessage}</div>}
      {errorMessage && <div className="alert-message error-message">{errorMessage}</div>}
      <div className="form-container">
        <ContactForm handleChange={handleChange} handleSubmit={handleSubmit} formData={formData} />
      </div>
    </div>
  );
};

export default ContactUs;
