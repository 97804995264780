import React from 'react';
import './HomePage.css';
import ChatImage from './images/mobilef-min3.png';
//import Confused from './images/confused.png';
import ComplexQuery from './images/complex_query.webp';
import Support from './images/247support.png';
import Flags from './images/flags.webp';
import Price from './images/price.webp';
import Costs from './images/costs.webp';
import Improvement from './images/improvement.webp';
import { Link } from 'react-router-dom'
import ExpandableCard from './card';
import User from './images/confused.png';
import BusinessUser from './images/business_representative4.png';
import Easy_Maintenance from './images/easy_maintenance.png';
import Implementation from './images/implementation.png';

const HomePage = () => (
  <main className="homepage">

    <section className="first-screen">
      <div className="first-text-block">
        <h1>Change Your Relationship With Your Customers</h1>
        <p className="hero_subhead">AIssie provides real-time responses to customer enquiries, alleviating pressure on your support team and enhancing the experience for your customers – it's a win-win!</p>
        <Link to="/demo">
          <button type="button" className='button-home'>
            Try Now!
          </button>
        </Link>      </div>
      <div className="image-placeholder">
        <img src={ChatImage} alt="AI Assistant" className="hero-image" />
      </div>
    </section>

    <section className="large-container">
      <div className="problem-statement">
        <div className="second-text-block">
          <h2>Current Challenges in Business Services</h2>
          <p>We recognise the difficulties both users and business representatives encounter in accessing and managing information effectively.</p>
          <div className='challenges'>
            {/* <div className="image-placeholder">
              <img src={Confused} alt="AI Assistant" className="hero-image" />
            </div>

            {/*<p>Have you, as a user, ever found it challenging to locate the information you need on a Council's website? Perhaps you've navigated through numerous FAQ pages and interacted with outdated chatbots that offer limited assistance, only to abandon your search or resort to calling the council directly. We understand these frustrations. However, we have some good news! AIssie has been created to transform the way users interact with Council websites through the use of the latest AI technology.</p>*/}
            <div className='cards'>
              <ExpandableCard
                title="User"
                image={User} // replace with your image source
                //title="24/7 Instant Support"
                summary="User Challenges"
                details="
                -Absence of real-time assistance options for immediate help \n
                -Limited communication options such as live chat or social medias \n
                -Trouble finding the right information on websites \n
                -Poorly organised or incomplete FAQ sections \n
                -Limited availability of information in multiple languages.
          
              
              "
              />
              <ExpandableCard
              title="Business Representative"
                image={BusinessUser} // replace with your image source
                summary="Business Representative Challenges"
                details="-Ensuring easy user access to information \n
                Training staff to adhere to procedures and accurately respond to enquiries    \n
                Managing and maintaining the knowledge base, often requiring IT support \n
                Analysing user interactions with the knowledge base to drive improvements \n
                Translating content into various languages
              "
              />
            </div>
          </div>
        </div>



      </div>

    </section>

    <section className="solution">
      <h2>Our Solution: AIssie Chatbot</h2>
      <p>Our solution offers real-time, multilingual support, understanding nuances and providing accurate information promptly by using latest AI technology.</p>
      <Link to="/demo">
        <button type="button" className='button-home'>
          Try Now!
        </button>
      </Link>
    </section>

    <section className="solution">
      <h3>How it Works</h3>
      <div className="solution-items">
        <article className="solution-item">
          <div className="solution-card solution-hover-effect">
            <div className="card-front brown">
              <div className='cardNumber brown'><span>1</span></div>
              <div className='cardHeader'>
                <span>Upload your Knowledge Base!</span>
              </div>

              <div className='cardDetails'>
                <p>More Details</p>
              </div>
            </div>
            <div className="card-back brown">
              <p>Sign up to our website to create your chatbot and upload your knowledge base, such as FAQs, business files and/or your website. AIssie will learn from what you have provided and will be ready to answer enquiries related to your business!</p>
            </div>
          </div>
        </article>
        <article className="solution-item">
          <div className="solution-card solution-hover-effect">
            <div className="card-front yellow">
              <div className='cardNumber yellow'><span>2</span></div>
              <div className='cardHeader'>
                <span>Test It!</span>
              </div>
              <div className='cardDetails'>
                <p>More Details</p>
              </div>
            </div>
            <div className="card-back yellow">
              <p>You'll be able to test the chatbot. Feel free to test it out by asking all sorts of questions in any language you prefer. We'll be keen to hear how it performs for you!</p>
            </div>
          </div>
        </article>
        <article className="solution-item">
          <div className="solution-card solution-hover-effect">
            <div className="card-front green">
              <div className='cardNumber green'><span>3</span></div>
              <div className='cardHeader'>
                <span>Deploy!</span>
              </div>
              <div className='cardDetails'>
                <p>More Details</p>
              </div>
            </div>
            <div className="card-back green">
              <p>We make integrating our Chatbot solution a breeze. We'll provide a simple code snippet that your IT team can effortlessly add to the necessary pages on your website. Alternatively we may also integrate with your social medias such as Facebook or Whatsapp!</p>
            </div>
          </div>
        </article>
        <article className="solution-item">
          <div className="solution-card solution-hover-effect">
            <div className="card-front blue">
              <div className='cardNumber blue'><span>4</span></div>
              <div className='cardHeader'>
                <span>Review!</span>
              </div>
              <div className='cardDetails'>
                <p>More Details</p>
              </div>
            </div>
            <div className="card-back blue">
              <p>Take a look at how the AIssie bot handled real customer questions and then update your Knowledge Base based on what you find. This way, the AIssie bot will get even better at responding next time around!</p>
            </div>
          </div>
        </article>

      </div>
    </section>


    <section className="benefits">
      <h3>Key Benefits</h3>
      <div className="benefit-items">

        <article className="benefit-item">
          <div className="benefit-card">
            <div className="card-front">
              <h4>Easy Implementation</h4>
              <div className="benefits-image">
                <img src={Implementation} alt="Easy Implementation" />
              </div>

              <div className='cardDetails'>
                <p>More Details</p>
              </div>
            </div>
            <div className="card-back">
              <p>Say goodbye to complicated consulting projects with multiple stakeholders, delayed timelines, and exceeded budgets. We'll have you up and running in just one day!</p>
            </div>
          </div>
        </article>
        <article className="benefit-item">
          <div className="benefit-card">
            <div className="card-front">
              <h4>Pay As You Go</h4>
              <div className="benefits-image">
                <img src={Price} alt="Pay As You Go" />
              </div>

              <div className='cardDetails'>
                <p>More Details</p>
              </div>
            </div>
            <div className="card-back">
              <p>Select one of our subscription options that match your business expectations and pay as you go. This structure combines the reliability of consistent service with the flexibility of usage-based expenses.</p>
            </div>
          </div>
        </article>
        <article className="benefit-item">
          <div className="benefit-card">
            <div className="card-front">
              <h4>Reduce Customer Service Costs</h4>
              <div className="benefits-image">
                <img src={Costs} alt="Reduce Customer Service Costs" />
              </div>
              <div className='cardDetails'>
                <p>More Details</p>
              </div>
            </div>
            <div className="card-back">
              <p>
                Using customer service chatbots can cut costs, as they replace the need for extra staff and offer 24/7 service, leading to efficient resource use and savings in customer service.</p>
            </div>
          </div>
        </article>
        <article className="benefit-item">
          <div className="benefit-card">
            <div className="card-front">
              <h4>Multilingual Communication</h4>
              <div className="benefits-image">
                <img src={Flags} alt="Multilingual Communication" />
              </div>              <div className='cardDetails'>
                <p>More Details</p>
              </div>
            </div>
            <div className="card-back">
              <p>AIssie chatbots can converse in multiple languages, breaking down language barriers. This feature is especially beneficial in diverse communities, allowing more customers to access business services in their preferred language.</p>
            </div>
          </div>
        </article>


      </div>
      <div className="benefit-items">
      <article className="benefit-item">
          <div className="benefit-card">
            <div className="card-front">
              <h4>Easy Maintenance</h4>
              <div className="benefits-image">
                <img src={Easy_Maintenance} alt="Easy Maintenance" />
              </div>

              <div className='cardDetails'>
                <p>More Details</p>
              </div>
            </div>
            <div className="card-back">
              <p>Maintain your Knowledge Base up to date and simply upload it to our Admin Portal – it's that easy! No need for IT support.</p>
            </div>
          </div>
        </article>
        
        <article className="benefit-item">
          <div className="benefit-card">
            <div className="card-front">
              <h4>Understanding Complex Queries</h4>
              <div className="benefits-image">
                <img src={ComplexQuery} alt="Understanding Complex Queries" />
              </div>

              <div className='cardDetails'>
                <p>More Details</p>
              </div>
            </div>
            <div className="card-back">
              <p>Thanks to the advanced AI technology, AIssie chatbots can understand and process complex questions, providing precise and relevant answers. This level of comprehension is a significant step up from standard chatbots, which often struggle with anything beyond simple queries.</p>
            </div>
          </div>
        </article>
                
        <article className="benefit-item">
          <div className="benefit-card">
            <div className="card-front">
              <h4>24/7 Instant Support</h4>
              <div className="benefits-image">
                <img src={Support} alt="24/7 Instant Support" />
              </div>               <div className='cardDetails'>
                <p>More Details</p>
              </div>
            </div>
            <div className="card-back">
              <p>AIssie chatbots are available around the clock, ensuring that customers can get immediate responses to their enquiries any time of the day. Unlike traditional methods, there's no need to wait for office hours or navigate through outdated FAQ pages.</p>
            </div>
          </div>
        </article>
        
        <article className="benefit-item">
          <div className="benefit-card">
            <div className="card-front">
              <h4>Continuous Improvement</h4>
              <div className="benefits-image">
                <img src={Improvement} alt="Continuous Improvement & Easy Maintenance" />
              </div>              <div className='cardDetails'>
                <p>More Details</p>
              </div>
            </div>
            <div className="card-back">
              <p>By regularly reviewing interactions with the chatbot and keeping the knowledge base current, the AIssie chatbot will consistently enhance its performance, ensuring it delivers precise and relevant responses to your customers.</p>
            </div>
          </div>
        </article>
      </div>
    </section>
    <div>
    </div>
  </main>
);


export default HomePage;
