import React from 'react';
import './Privacy.css'; // Assuming a CSS file for styling

const Privacy = () => {
return (
<div className='privacy'>
    <h2>Privacy Policy</h2>
    <p>This Policy was last reviewed and updated on: 03/04/2024</p>
    <p>AIssie values and respects the privacy of the people we deal with. AIssie is committed to protecting your privacy
        and complying with the Privacy Act 1988 (Cth) (Privacy Act) and other applicable privacy laws and regulations.
    </p>
    <p>This Privacy Policy (Policy) describes how we collect, hold, use and disclose your personal information, and how
        we maintain the quality and security of your personal information.</p>


    <h3>What is personal information?</h3>
    <p>“Personal information” means any information or opinion, whether true or not, and whether recorded in a material
        form or not, about an identified individual or an individual who is reasonably identifiable. In general terms,
        this includes information or an opinion that personally identifies you either directly (e.g. your name) or
        indirectly.</p>
    <h3>What personal information do we collect?</h3>
    <p>The personal information we collect about you depends on the nature of your dealings with us or what you choose
        to share with us.</p>
    <p>
        The personal information we collect about you may include:
    <ul>

        <li>name;</li>
        <li>company;</li>
        <li>email address;</li>
        <li>phone number;</li>
        <li>job title</li>
    </ul>
    </p>
    <p>You do not have to provide us with your personal information. Where possible, we will give you the option to
        interact with us anonymously or by using a pseudonym. However, if you choose to deal with us in this way or
        choose not to provide us with your personal information, we may not be able to provide you with our services or
        otherwise interact with you.</p>
    <h3>How do we collect your personal information?</h3>
    <p>We collect your personal information directly from you when you:
    <ul>
        <li>interact with us over the phone;</li>
        <li>interact with us in person;</li>
        <li>interact with us online;</li>
        <li>participate in surveys or questionnaires;</li>
        <li>attend a AIssie event;</li>
        <li>subscribe to our mailing list;</li>
        <li>apply for a position with us as an employee, contractor or volunteer;
        </li>
    </ul>
    </p>
    <p>We do not collect your personal information from third parties or through publicly available sources</p>
    <h3>How do we use your personal information?</h3>
    <p>We use personal information for many purposes in connection with our functions and activities, including the
        following purposes:
    <ul>
        <li>deliver to you a more personalised experience and service offering; </li>
        <li>improve the quality of the services we offer;</li>
        <li>internal administrative purposes;</li>
        <li>marketing and research purposes;</li>

    </ul>
    </p>
    <h3>Disclosure of personal information to third parties</h3>
    <p>
        The personal information AIssie collects from you is stored in one or more databases hosted by third parties
        located in the United States. These third parties do not use or have access to your personal information for any
        purpose other than cloud storage and retrieval. On occasion, AIssie engages third parties to send information to
        you, including information about our products, services, and events.
        The following list of our third party sub processors can be found here:
    <ul>
        <li>OpenAI (AI Service) </li>
        <li>PythonAnywhere (Web Hosting service)</li>
        <li>Google (Email)</li>
        <li>Redis (Database)</li>
        <li>VentraIP (Web Hosting service)</li>
    </ul>
    We do not otherwise reveal your personal data to non-AIssie persons or businesses for their independent use unless:
    (1) you request or authorize it; (2) it’s in connection with AIssie-hosted and AIssie co-sponsored conferences as
    described above; (3) the information is provided to comply with the law (for example, compelled by law enforcement
    to comply with a search warrant, subpoena, or court order), enforce an agreement we have with you, or to protect our
    rights, property or safety, or the rights, property or safety of our employees or others; (4) the information is
    provided to our agents, vendors or service providers who perform functions on our behalf; (5) to address emergencies
    or acts of God; or (6) to address disputes, claims, or to persons demonstrating legal authority to act on your
    behalf. We may also gather aggregated data about our services and website visitors and disclose the results of such
    aggregated (but not personally identifiable) information to our partners, service providers, advertisers, and/or
    other third parties for marketing or promotional purposes.


    </p>
    <h3>Transfer of personal information overseas</h3>
    <p>
        Some of the third-party service providers we disclose personal information to may be based in or have servers
        located outside of Australia, including in United States.
    </p>
    <p>
        Where we disclose your personal information to third parties overseas, we will take reasonable steps to ensure
        that data security and appropriate privacy practices are maintained. We will only disclose to overseas third
        parties if:
    <ul>

        <li>you have given us your consent to disclose personal information to that third party; or</li>
        <li>we reasonably believe that:</li>
        <li>
        <li> the overseas recipient is subject to a law or binding scheme that is, overall, substantially similar to the
            APPs; and </li>
        </li>
        <li>
        <li> the law or binding scheme can be enforced; or</li>
        </li>
        <li>the disclosure is required or authorised by an Australian law or court / tribunal order.</li>
    </ul>
    </p>
    <h3>How do we protect your personal information?</h3>
    <p>AIssie will take reasonable steps to ensure that the personal information that we hold about you is kept
        confidential and secure, including by:
    <ul>
        <li>taking measures to restrict access to only personnel who need that personal information to effectively
            provide services to you;</li>
        <li>having technological measures in place (for example, anti-virus software, fire walls); </li>

    </ul>
    </p>
    <h3>Online activity</h3>
    <p>
        The AIssie website uses cookies. A cookie is a small file of letters and numbers the website puts on your device
        if you allow it. These cookies recognise when your device has visited our website(s) before, so we can
        distinguish you from other users of the website. This improves your experience and the AIssie website(s).
    </p>
    <p>

        We do not use cookies to identify you, just to improve your experience on our website(s). If you do not wish to
        use the cookies, you can amend the settings on your internet browser so it will not automatically download
        cookies. However, if you remove or block cookies on your computer, please be aware that your browsing experience
        and our website’s functionality may be affected.
    </p>
    <p>
        We may send you direct marketing communications and information about our services, opportunities, or events
        that we consider may be of interest to you if you have requested or consented to receive such communications.
        These communications may be sent in various forms, including mail, SMS, fax and email, in accordance with
        applicable marketing laws, such as the Australian Spam Act 2003 (Cth). You consent to us sending you those
        direct marketing communications by any of those methods. If you indicate a preference for a method of
        communication, we will endeavour to use that method whenever practical to do so.
    </p>
    <p>

        You may opt-out of receiving marketing communications from us at any time by contacting us using the details set
        out in the “How to contact us” section below.
    </p>
    <p>
        In addition, we may also use your personal information or disclose your personal information to third parties
        for the purposes of advertising, including online behavioural advertising, website personalisation, and to
        provide targeted or retargeted advertising content to you (including through third party websites).
    </p>
    <h3>Retention of personal information</h3>
    <p>AIssie stores your personal data on its own servers as well as on those used by the cloud-based database
        management services we engage with, situated both in the United States and Australia. The duration for which
        AIssie retains service data extends through the period of our business relationship with you, followed by
        additional time to utilise the data for AIssie's operational analysis and for historical and archival purposes
        linked to our services. We keep prospect data until it is no longer of business value and then it is removed
        from AIssie's systems. Upon receiving a verified request from Data Subjects or their authorised agents, we can
        delete any personal data under AIssie's control. Should you need further details about the storage duration and
        location of your personal data, or wish to learn more about your rights regarding erasure and data portability,
        kindly reach out to us at contactus@aissie.com.au</p>
    <h3>Review of Third-Party Privacy Policy</h3>
    <p>Our Service utilises OpenAI APIs to generate responses. As a result, some user data may be processed by OpenAI in
        accordance with their Privacy Policy. We encourage you to review OpenAI's Privacy Policy, which can be found <a
            href="https://openai.com/policies/api-data-usage-policies"> here</a>, to understand how your data may be
        processed when using our Service.</p>
    <h3>How to access and correct your personal information</h3>
    <p>AIssie will endeavour to keep your personal information accurate, complete and up to date.

        If you wish to make a request to access and / or correct the personal information we hold about you, you should
        make a request by contacting us and we will usually respond within 30 days.
    </p>
    <h3>Inquiries and complaints</h3>
    <p>For complaints about how AIssie handles, processes or manages your personal information, please contact
        contactus@aissie.com.au. Note we may require proof of your identity and full details of your request before we
        can process your complaint.
    </p>
    <p>
        Please allow up to 30 days for AIssie to respond to your complaint. It will not always be possible to resolve a
        complaint to everyone’s satisfaction. If you are not satisfied with AIssie’s response to a complaint, you have
        the right to contact the Office of Australian Information Commissioner (at www.oaic.gov.au/) to lodge a
        complaint.
    </p>
    <h3>How to contact us</h3>
    <p>If you have a question or concern in relation to our handling of your personal information or this Policy, you
        can contact us for assistance as follows:
    </p>
    <p>Email:
        contactus@aissie.com.au

    </p>
</div>
);
};

export default Privacy;