import React from 'react';
import './Footer.css';

const Footer = () => (
  <footer className="footer">
    <p>&copy; {new Date().getFullYear()} AIssie. All rights reserved.</p>
    <nav>
      <a href="/terms-and-conditions">Terms and Conditions</a>
      <a href="/privacy-policy">Privacy Policy</a>
    </nav>
  </footer>
);

export default Footer;
